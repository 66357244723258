import React, { Component } from "react";
// import Zmage from "react-zmage";
// import Fade from "react-reveal";
// import ImageGallery from 'react-image-gallery';
import DztImageGalleryComponent from "reactjs-image-gallery";
// let id = 0;

const data = [
    {
      url: 'images/portfolio/BNB_pic2.jpg',
      thumbUrl:'images/portfolio/resize/BNB_pic2.jpg',
      title: "Indgang",
      
    },
    {
        url: 'images/portfolio/car_park.jpg',
        thumbUrl:'images/portfolio/resize/car_park.jpg',
        title: "Parkeringsplads",
        
      },
    {
        url: 'images/portfolio/hallway_updated.png',
        thumbUrl:  'images/portfolio/resize/hallway_updated.png',
        title: "Gang",
    },
    {
        url: 'images/portfolio/new_room1_1.jpg',
        thumbUrl:  'images/portfolio/resize/new_room1_1.jpg',
        title: "Værelse 1",
    },
    {
        url: 'images/portfolio/new_room1_2.jpg',
        thumbUrl:  'images/portfolio/resize/new_room1_2.jpg',
        title: "Værelse 1, anden visning",
    },
    {
        url: 'images/portfolio/room_2_1.png',
        thumbUrl:  'images/portfolio/resize/room_2_1.png',
        title: "Værelse 2",
    },
    {
        url: 'images/portfolio/room2_2.png',
        thumbUrl:  'images/portfolio/resize/room2_2.png',
        title: "Værelse 2, anden visning",
    },
    {
        url: 'images/portfolio/v3_1.png',
        thumbUrl:  'images/portfolio/resize/v3_1.png',
        title: "Værelse 3",
    },
    {
        url: 'images/portfolio/v3_2.png',
        thumbUrl:  'images/portfolio/resize/v3_2.png',
        title: "Værelse 3, anden visning",
    }
    ,
    {
        url: 'images/portfolio/room_5_1.png',
        thumbUrl:  'images/portfolio/resize/room_5_1.png',
        title: "Værelse 5",
    },
    {
        url: 'images/portfolio/room_5_2.png',
        thumbUrl:  'images/portfolio/resize/room_5_2.png',
        title: "Værelse 5, anden visning",
    },
    {
        url: 'images/portfolio/room5_two_single_beds_view1.jpg',
        thumbUrl:  'images/portfolio/resize/room5_two_single_beds_view1.jpg',
        title: "Værelse 5, med 2 enkeltsenge",
    },
    {
        url: 'images/portfolio/room5_two_single_beds_view2.jpg',
        thumbUrl:  'images/portfolio/resize/room5_two_single_beds_view2.jpg',
        title: "Værelse 5, med 2 enkeltsenge, en anden udsigt",
    },
    {
        url: 'images/portfolio/room5_two_single_beds_view3.jpg',
        thumbUrl:  'images/portfolio/resize/room5_two_single_beds_view3.jpg',
        title: "Værelse 5, med 2 enkeltsenge, en anden udsigt",
    },
    {
        url: 'images/portfolio/room_6_1.png',
        thumbUrl:  'images/portfolio/resize/room_6_1.png',
        title: "Værelse 6",
    },
    {
        url: 'images/portfolio/room_6_2.png',
        thumbUrl:  'images/portfolio/resize/room_6_2.png',
        title: "Værelse 6, anden visning",
    },
    {
        url: 'images/portfolio/bad_1.png',
        thumbUrl:  'images/portfolio/resize/bad_1.png',
        title: "Badeværelse",
    },
    {
        url: 'images/portfolio/bad_2.png',
        thumbUrl:  'images/portfolio/resize/bad_2.png',
        title: "Badeværelse, anden visning",
    },
    {
        url: 'images/portfolio/bad_3.png',
        thumbUrl:  'images/portfolio/resize/bad_3.png',
        title: "Badeværelse, toilet",
    },
    {
        url: 'images/portfolio/bad_shower.jpg',
        thumbUrl:  'images/portfolio/resize/bad_shower.jpg',
        title: "Badeværelse, brusebad",
    },
    {
        url: 'images/portfolio/second_bathroom_1.png',
        thumbUrl:  'images/portfolio/resize/second_bathroom_1.png',
        title: "Andet badeværelse",
    },
    {
        url: 'images/portfolio/second_bathroom_2.png',
        thumbUrl:  'images/portfolio/resize/second_bathroom_2.png',
        title: "Andet badeværelse, anden visning",
    },
    {
        url: 'images/portfolio/kokken_1.png',
        thumbUrl:  'images/portfolio/resize/kokken_1.png',
        title: "Køkken",
    },
   
    {
        url: 'images/portfolio/dining_room_1.png',
        thumbUrl:  'images/portfolio/resize/dining_room_1.png',
        title: "Spisestue",
    },
    {
        url: 'images/portfolio/dining_room_2.png',
        thumbUrl:  'images/portfolio/resize/dining_room_2.png',
        title: "Spisestue, anden visning",
    },
    {
        url: 'images/portfolio/BNB_pic6.jpg',
        thumbUrl:  'images/portfolio/resize/BNB_pic6.jpg',
        title: "Landskabet",
    },
    {
        url: 'images/portfolio/have1_1.jpg',
        thumbUrl:  'images/portfolio/resize/have1_1.jpg',
        title: "Have",
    },
    {
        url: 'images/portfolio/have1_2.jpg',
        thumbUrl:  'images/portfolio/resize/have1_2.jpg',
        title: "Have",
    },
    {
        url: 'images/portfolio/have1_3.jpg',
        thumbUrl:  'images/portfolio/resize/have1_3.jpg',
        title: "Have",
    },
    {
        url: 'images/portfolio/have1_4.jpg',
        thumbUrl:  'images/portfolio/resize/have1_4.jpg',
        title: "Have",
    },
    {
        url: 'images/portfolio/have1_5.jpg',
        thumbUrl:  'images/portfolio/resize/have1_5.jpg',
        title: "Have",
    },
    {
        url: 'images/portfolio/have1_6.jpg',
        thumbUrl:  'images/portfolio/resize/have1_6.jpg',
        title: "Have",
    },
    {
        url: 'images/portfolio/have1_7.jpg',
        thumbUrl:  'images/portfolio/resize/have1_7.jpg',
        title: "Have",
    },
    {
        url: 'images/portfolio/have1_8.jpg',
        thumbUrl:  'images/portfolio/resize/have1_8.jpg',
        title: "Have",
    },
    {
        url: 'images/portfolio/deer.png',
        thumbUrl:  'images/portfolio/resize/deer.png',
        title: "Rådyr i have",
    },
    {
        url: 'images/portfolio/night1.jpg',
        thumbUrl:  'images/portfolio/resize/night1.jpg',
        title: "Indgang om natten",
    },
    {
        url: 'images/portfolio/night2.jpg',
        thumbUrl:  'images/portfolio/resize/night2.jpg',
        title: "Indkørsel om natten",
    }
    ,
    {
        url: 'images/portfolio/night3.jpg',
        thumbUrl:  'images/portfolio/resize/night3.jpg',
        title: "B&B -bygning om natten",
    }
    ,
    {
        url: 'images/portfolio/night4.jpg',
        thumbUrl:  'images/portfolio/resize/night4.jpg',
        title: "Gård om natten",
    }
    ,
    {
        url: 'images/portfolio/frugt1.jpg',
        thumbUrl:  'images/portfolio/resize/frugt1.jpg',
        title: "Frugt",
    }
    ,
    {
        url: 'images/portfolio/frugt2.jpg',
        thumbUrl:  'images/portfolio/resize/frugt2.jpg',
        title: "Frugt",
    }
    ,
    {
        url: 'images/portfolio/frugt3.jpg',
        thumbUrl:  'images/portfolio/resize/frugt3.jpg',
        title: "Frugt",
    }
    ,
    {
        url: 'images/portfolio/Have20210509_1.jpg',
        thumbUrl:  'images/portfolio/resize/Have20210509_1.jpg',
        title: "Have",
    }
    ,
    {
        url: 'images/portfolio/Have20210509_2.jpg',
        thumbUrl:  'images/portfolio/resize/Have20210509_2.jpg',
        title: "Have",
    }
    ,
    {
        url: 'images/portfolio/Have20210509_3.jpg',
        thumbUrl:  'images/portfolio/resize/Have20210509_3.jpg',
        title: "Have",
    },
    {
        url: 'images/portfolio/deer_20211229.jpg',
        thumbUrl:  'images/portfolio/resize/deer_20211229.jpg',
        title: "Rådyr i have",
    },
    {
        url: 'images/portfolio/campfire_image0.png',
        thumbUrl:  'images/portfolio/resize/campfire_image0.png',
        title: "Lejrbål, visning 1",
    },
    {
        url: 'images/portfolio/campfire_image1.png',
        thumbUrl:  'images/portfolio/resize/campfire_image1.png',
        title: "Lejrbål, visning 2",
    },
    {
        url: 'images/portfolio/campfire_image2.png',
        thumbUrl:  'images/portfolio/resize/campfire_image2.png',
        title: "Lejrbål, visning 3",
    },
    {
        url: 'images/portfolio/campfire_image3.png',
        thumbUrl:  'images/portfolio/resize/campfire_image3.png',
        title: "Lejrbål, visning 4",
    }
    ,
    {
        url: 'images/portfolio/terrace_1.png',
        thumbUrl:  'images/portfolio/resize/terrace_1_resize.png',
        title: "Terrasse, visning 1",
    },
    {
        url: 'images/portfolio/terrace_2.png',
        thumbUrl:  'images/portfolio/resize/terrace_2_resize.png',
        title: "Terrasse, visning 2",
    }
    
    

    
  ];


class Portfolio2 extends Component {
  render() {
    if (!this.props.data) return null;

    
     

   

    return (
      <section id="portfolio">
    
    <div>
        <DztImageGalleryComponent images={data} />
      </div>
           
         
           
      </section>
    );
  }
}

export default Portfolio2;
